import React, { useEffect, useState } from "react";
import "./AllDigitalLeaders.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useNavigate } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import { MdArrowBackIosNew } from "react-icons/md";
import Swal from "sweetalert2";
import GlobalURL from "../../../BaseUrl";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import translations from "../../../../locals/translation";
import Chart from "react-google-charts";
import { FiSearch } from "react-icons/fi";

function AllDigitalLeaders() {
  const [dummyAvailable, setDummyAvailable] = useState(false);

  const language = useSelector((state) => state.language.value);

  const t = (key) => {
    // Check if translations exist for the current language
    if (translations[language]) {
      // Access translation for the provided key
      const translation = translations[language][key];
      // Return translation if found, otherwise return the key itself
      return translation !== undefined ? translation : key;
    } else {
      // Return the key itself if translations for the current language are not available
      return key;
    }
  };

  const [data, setData] = useState([]);

  const [data2, setData2] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [disabled, setDisable] = useState(false);

  const [dummyData, setDummyData] = useState([]);

  const [searchMessage, setSearchMessage] = useState("");

  const [originalData, setOriginalData] = useState("");

  const [searchActive, setSearchActive] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(true);

  const [loadSearch, setLoadSearch] = useState(false);

  const [reports, setReports] = useState([]);
  const [repdetails, setRepdetails] = useState({
    type: "",
    id: "",
    reason: "",
    by: "",
  });
  const [showRepDet, setShowRepDet] = useState(false);
  const navigate = useNavigate();

  const [tab2, setTab] = useState("todas");

  async function getData() {
    // const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllVerifiedUser`;
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    // const apiUrl = `https://fancy-valued-goat.ngrok-free.app/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)

      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
        setLoading(false);
      }

      if (response.ok) {
        setLoading(false);
        const responseData = await response.json();
        console.log("responseData.data: ", responseData.data.user);

        const temp = { gold: 0, platinum: 0, diamond: 0 };

        responseData.data.user.forEach((item) => {
          switch (item.plan_id) {
            case 1:
              temp.gold++;
              break;
            case 2:
              temp.gold++;
              break;
            case 3:
              temp.gold++;
              break;
            case 4:
              temp.gold++;
              break;
            case 5:
              temp.platinum++;
              break;
            case 6:
              temp.diamond++;
              break;
            default:
              break;
          }
        });

        const updatedData = [
          ["Subscription", "Users"],
          ["Plata", temp.platinum], // Update Platinum count
          ["Oro", temp.gold], // Update Gold count
          ["Diamante", temp.diamond], // Update Diamond count
        ];

        setData2(updatedData);
        console.log("user profiles", responseData.data);
        setLoading(false);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
        setLoading(false);
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      // Swal.fire(t('Error del Servidor'));
      setLoading(false);
    }
  }
  const handlePageCount = async (e) => {
    setCurrentPage(currentPage + 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        setOriginalData(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 5) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  };
  const handlePageCountdec = async (e) => {
    setCurrentPage(currentPage - 1);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 5) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  };
  async function getReports() {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllReport`;
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.message === "No Data Found") {
          return;
        }
        setReports(responseData.data);

        console.log("reports", responseData.data);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  }
  const formatDate = (dateString) => {
    const today = new Date();
    const parsedDate = new Date(dateString);

    if (
      parsedDate.getDate() === today.getDate() &&
      parsedDate.getMonth() === today.getMonth() &&
      parsedDate.getFullYear() === today.getFullYear()
    ) {
      // The date is today
      const hours = parsedDate.getHours();
      const minutes = parsedDate.getMinutes();
      const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}hs`;

      return `hoy, ${formattedTime}`;
    } else {
      // If it's not today, you can customize the format as needed
      const options = { weekday: "long", hour: "numeric", minute: "numeric" };
      return parsedDate.toLocaleString("es-ES", options);
    }
  };

  //   const handleSearch = (e) => {
  //     switch (tab) {
  //       case "oro":
  //         console.log(tab);
  //         let oroDataTemp = data.filter((item) => item.plan_id === 1);
  //         const oroOriginalData = data.filter((item) => item.plan_id === 1);
  //         setSearchTerm(e.target.value);
  //         console.log("Search Term: ", e.target.value);
  //         if (e.target.value === "") {
  //           setData(oroOriginalData);
  //           return;
  //         }
  //         const oroResult = oroDataTemp.filter((item) =>
  //           item.first_name.toLowerCase().includes(e.target.value)
  //         );
  //         console.log("result: ", oroResult);
  //         if (oroResult.length === 0) {
  //           console.log("Empty Array executed:");
  //           return;
  //         }
  //         setData(oroResult);
  //         break;

  //       case "platino":
  //         console.log(tab);
  //         let platinoDataTemp = data.filter((item) => item.plan_id === 2);
  //         const platinoOriginalData = data.filter((item) => item.plan_id === 2);
  //         setSearchTerm(e.target.value);
  //         console.log("Search Term: ", e.target.value);
  //         if (e.target.value === "") {
  //           setData(platinoOriginalData);
  //           return;
  //         }
  //         const platinoResult = platinoDataTemp.filter((item) =>
  //           item.first_name.toLowerCase().includes(e.target.value)
  //         );
  //         console.log("result: ", platinoResult);
  //         if (platinoResult.length === 0) {
  //           console.log("Empty Array executed:");
  //           return;
  //         }
  //         setData(platinoResult);
  //         break;

  //       case "diamante":
  //         console.log(tab);
  //         let diamanteDataTemp = data.filter((item) => item.plan_id === 3);
  //         const diamanteOriginalData = data.filter((item) => item.plan_id === 3);
  //         setSearchTerm(e.target.value);
  //         console.log("Search Term: ", e.target.value);
  //         if (e.target.value === "") {
  //           setData(diamanteOriginalData);
  //           return;
  //         }
  //         const diamanteResult = diamanteDataTemp.filter((item) =>
  //           item.first_name.toLowerCase().includes(e.target.value)
  //         );
  //         console.log("result: ", diamanteResult);
  //         if (diamanteResult.length === 0) {
  //           console.log("Empty Array executed:");
  //           return;
  //         }
  //         setData(diamanteResult);
  //         break;
  //       default:
  //         const dataTemp = originalData;
  //         console.log("originalData: ", originalData);
  //         setSearchTerm(e.target.value);
  //         console.log("Search Term: ", e.target.value);
  //         if (e.target.value === "") {
  //           setLeaders(originalData);
  //           return;
  //         }
  //         const result = dataTemp.filter((item) =>
  //           item.first_name.toLowerCase().includes(e.target.value)
  //         );
  //         console.log("result: ", result);
  //         if (result.length === 0) {
  //           console.log("Empty Arry executed:");
  //           return;
  //         }
  //         setLeaders(result);
  //         break;
  //     }
  //   };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      getFirstData();
      console.log("Executed");
      setDummyData([]);
      setDummyAvailable(false);
    }
  };

  const getDataOfSearch = async (e) => {
    setDummyAvailable(true);
    setLoadSearch(!loadSearch);
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: searchTerm }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.data === null || responseData.data == []) {
          setSearchMessage(t("No Data in Search"));
          return;
        }
        setDummyData(responseData.data.user);

        console.log("leaders unverified for search", responseData.data.user);

        setLoadSearch(!loadSearch);
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        setLoadSearch(!loadSearch);
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      setLoadSearch(!loadSearch);
    }
  };

  const getFirstData = async (e) => {
    const apiUrl = `${GlobalURL}/api/dev/app_moderator/getAllDigitalManager`; // endpoint
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "ngrok-skip-browser-warning": "69420",
        },
        body: JSON.stringify({ search: "" }),
      });

      // Check if the request was successful (status code 2xx)
      if (response.code === 401) {
        Swal.fire(t("La sesión expiró, vuelva a iniciar sesión"));
        navigate("/login");
      }
      if (response.ok) {
        const responseData = await response.json();

        setLeaders(responseData.data.user);
        setOriginalData(responseData.data.user);
        console.log("leaders unverified", responseData.data.user);

        if (responseData.data.user.length < 5) {
          setDisable(true);
        }
      } else {
        // If the server returns an error status code
        const errorData = await response.json();
        Swal.fire(t("Error del Servidor"));
      }
    } catch (error) {
      // Handle any network or other errors that may occur during the fetch
      console.error("Error:", error.message);
      Swal.fire(t("Error del Servidor"));
    }
  };

  useEffect(() => {
    getData();
    getReports();
    getFirstData();
  }, []);

  const options = {
    title: "",
    legend: "none",
    pieSliceText: "label",
    backgroundColor: "transparent",
    titleTextStyle: {
      color: "#FEF6F2",
    },
    slices: {
      0: {
        offset: 0.1,
        color: "#005EAA",
        link: "/path/to/some/page",
      },
      1: {
        color: "#25762E",
        link: "/another/page",
      },
      2: {
        color: "#C37704",
        link: "/yet/another/page",
      },
    },
  };

  return (
    <>
      <div className="tabs-cards-sec">
        <div className="search-icon-in managers" style={{ display: "flex" }}>
          {
            <div className="searchBarInput">
              <input
                className="search-tag-bar"
                required={true}
                placeholder={t("Buscar")}
                value={searchTerm}
                onChange={(e) => {
                  handleSearchChange(e);
                }}
              />
            </div>
          }
          <FiSearch
            className="search-icon-click"
            onClick={() => {
              if (!searchTerm) {
                return;
              }
              getDataOfSearch();
            }}
          />
        </div>

        {/* tab section */}
        {dummyAvailable ? (
          <div style={{ fontFamily: "Francois One" }}>
            <Tabs>
              <Tab eventKey="todas" title={t("Resultado de búsqueda")}>
                <div className="tab-content-wrapper">
                  {loading ? (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="loading-adjust">
                        <div className="Loader d-flex justify-content-center align-items-center">
                          <div
                            className="spinner-border spinner-border-sm my-5"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                          <div className="my-5"> &nbsp; {t("Cargando...")}</div>
                        </div>
                      </div>
                    </div>
                  ) : dummyData.length === 0 ? (
                    <div style={{ fontSize: "18px" }}>
                      {t("No se encontró ningún líder")}
                    </div>
                  ) : (
                    dummyData.map((item, index) => {
                      return (
                        <div
                          className="todas-card"
                          key={index}
                          onClick={() => {
                            navigate("/leaderprofile", { state: item });
                          }}
                        >
                          <div className="left-sec">
                            <div className="profile-img">
                              <img src={item.image_url} alt="" />
                            </div>
                            <div className="diam-img">
                              {item.is_verified && (
                                <img
                                  src="./img/Diamante.png"
                                  className="gem-for-veri"
                                  alt="gem"
                                />
                              )}
                            </div>
                            <div className="profile-content">
                              <h5>{item.first_name + item.last_name}</h5>
                              <p>{item.username}</p>
                            </div>
                          </div>
                          <div className="right-sec">
                            <span className="todas-img">
                              {/* {(item.plan_id === 1 || item.plan_id === 2 || item.plan_id === 3 || item.plan_id === 4) && (<img src="./img/gold-bar.png" alt="" />)}
                                                                        {item.plan_id === 6 && (<img src="./img/diamand.png" className='gem-for-veri' alt="gem" />)}
                                                                        {item.plan_id === 5 && (<img src="./img/green-bar.png" className='gem-for-veri' alt="gem" />)} */}
                              <img
                                src="./img/green-bar.png"
                                className="gem-for-veri"
                                alt="gem"
                              />
                            </span>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        ) : (
          <>
            {loading ? (
              <div
                className=""
                style={{
                  height: "500px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="loading-adjust">
                  <div className="Loader d-flex justify-content-center align-items-center">
                    <div
                      className="spinner-border spinner-border-sm my-5"
                      role="status"
                    >
                      <span className="sr-only"></span>
                    </div>
                    <div className="my-5"> &nbsp; {t("Cargando...")}</div>
                  </div>
                </div>
              </div>
            ) : (
              <Tabs
                defaultActiveKey="todas" // default checked value
                id="fill-tab-example"
                fill
                style={{ fontFamily: "Francois One" }}
                // onSelect={(eventKey) => setTab(eventKey)}
              >
                <Tab eventKey="todas" title={t("TODAS")}>
                  <div className="tab-content-wrapper">
                    {loading ? (
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="loading-adjust">
                          <div className="Loader d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border spinner-border-sm my-5"
                              role="status"
                            >
                              <span className="sr-only"></span>
                            </div>
                            <div className="my-5">
                              {" "}
                              &nbsp; {t("Cargando...")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : leaders.length === 0 ? (
                      <div>{t("No se encontró ningún líder")}</div>
                    ) : (
                      leaders.map((item, index) => {
                        return (
                          <div
                            className="todas-card"
                            key={index}
                            onClick={() => {
                              navigate("/leaderprofile", { state: item });
                            }}
                          >
                            <div className="left-sec">
                              <div className="profile-img">
                                <img src={item.image_url} alt="" />
                              </div>
                              <div className="diam-img">
                                {item.is_verified && (
                                  <img
                                    src="./img/Diamante.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                              </div>
                              <div className="profile-content">
                                <h5>{item.first_name + item.last_name}</h5>
                                <p>{item.username}</p>
                              </div>
                            </div>
                            <div className="right-sec">
                              <span className="todas-img">
                                {(item.plan_id === 1 ||
                                  item.plan_id === 2 ||
                                  item.plan_id === 3 ||
                                  item.plan_id === 4) && (
                                  <img src="./img/gold-bar.png" alt="" />
                                )}
                                {item.plan_id === 6 && (
                                  <img
                                    src="./img/diamand.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                                {item.plan_id === 5 && (
                                  <img
                                    src="./img/green-bar.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </Tab>
                <Tab eventKey="oro" title={t("ORO")}>
                  {leaders.filter(
                    (item) =>
                      item.plan_id === 1 ||
                      item.plan_id === 2 ||
                      item.plan_id === 3 ||
                      item.plan_id === 4
                  ).length < 1 ? (
                    <div>{t("No hay miembros ORO disponibles")}</div>
                  ) : (
                    leaders
                      .filter(
                        (item) =>
                          item.plan_id === 1 ||
                          item.plan_id === 2 ||
                          item.plan_id === 3 ||
                          item.plan_id === 4
                      )
                      .map((item, index) => {
                        return (
                          <div
                            className="todas-card"
                            key={index}
                            onClick={() => {
                              navigate("/leaderprofile", { state: item });
                            }}
                          >
                            <div className="left-sec">
                              <div className="profile-img">
                                <img src={item.image_url} alt="" />
                              </div>

                              <div className="profile-content">
                                <h5>{item.first_name + item.last_name}</h5>
                                <p>{item.username}</p>
                              </div>
                            </div>
                            <div className="right-sec">
                              <span className="todas-img">
                                {(item.plan_id === 1 ||
                                  item.plan_id === 2 ||
                                  item.plan_id === 3 ||
                                  item.plan_id === 4) && (
                                  <img src="./img/gold-bar.png" alt="" />
                                )}
                                {item.plan_id === 6 && (
                                  <img
                                    src="./img/diamand.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                                {item.plan_id === 5 && (
                                  <img
                                    src="./img/green-bar.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })
                  )}
                </Tab>
                <Tab eventKey="platino" title={t("PLATINO")}>
                  {leaders.filter((item) => item.plan_id === 5).length < 1 ? (
                    <div
                      style={{ marginTop: "0px" }}
                      className="set_normalText"
                    >
                      {t("No hay miembro PLATINO disponible")}
                    </div>
                  ) : (
                    leaders
                      .filter((item) => item.plan_id === 5)
                      .map((item, index) => {
                        return (
                          <div
                            className="todas-card"
                            key={index}
                            onClick={() => {
                              navigate("/leaderprofile", { state: item });
                            }}
                          >
                            <div className="left-sec">
                              <div className="profile-img">
                                <img src={item.image_url} alt="" />
                              </div>

                              <div className="profile-content">
                                <h5>{item.first_name + item.last_name}</h5>
                                <p>{item.username}</p>
                              </div>
                            </div>
                            <div className="right-sec">
                              <span className="todas-img">
                                {(item.plan_id === 1 ||
                                  item.plan_id === 2 ||
                                  item.plan_id === 3 ||
                                  item.plan_id === 4) && (
                                  <img src="./img/gold-bar.png" alt="" />
                                )}
                                {item.plan_id === 6 && (
                                  <img
                                    src="./img/diamand.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                                {item.plan_id === 5 && (
                                  <img
                                    src="./img/green-bar.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })
                  )}
                </Tab>
                <Tab eventKey="diamante" title={t("DIAMANTE")}>
                  {leaders.filter((item) => item.plan_id === 6).length < 1 ? (
                    <div className="set_normalText">
                      {" "}
                      {t("Ningún miembro de DIAMANTE encontrado")}{" "}
                    </div>
                  ) : (
                    leaders
                      .filter((item) => item.plan_id === 6)
                      .map((item, index) => {
                        return (
                          <div
                            className="todas-card"
                            key={index}
                            onClick={() => {
                              navigate("/leaderprofile", { state: item });
                            }}
                          >
                            <div className="left-sec">
                              <div className="profile-img">
                                <img src={item.image_url} alt="" />
                              </div>

                              <div className="profile-content">
                                <h5>{item.first_name + item.last_name}</h5>
                                <p>{item.username}</p>
                              </div>
                            </div>
                            <div className="right-sec">
                              <span className="todas-img">
                                {(item.plan_id === 1 ||
                                  item.plan_id === 2 ||
                                  item.plan_id === 3 ||
                                  item.plan_id === 4) && (
                                  <img src="./img/gold-bar.png" alt="" />
                                )}
                                {item.plan_id === 6 && (
                                  <img
                                    src="./img/diamand.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                                {item.plan_id === 5 && (
                                  <img
                                    src="./img/green-bar.png"
                                    className="gem-for-veri"
                                    alt="gem"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        );
                      })
                  )}
                </Tab>
              </Tabs>
            )}

            {/* <div className='page-shifting'>
                                <div className='current-page-text'>{t('Página:')}<div className='page-count'>{currentPage + 1}</div> </div>
                                <button className='icon-arrow' disabled={currentPage < 1} onClick={handlePageCountdec}>{<MdArrowBackIosNew />}</button>
                                <button className='icon-arrow' disabled={disabled} onClick={handlePageCount}>{<MdArrowForwardIos />}</button>
                            </div> */}
          </>
        )}
      </div>

      <>
        <div className="chart-graph">
          <div className="team-list-char-sec">
            <div
              className="piechart-sec text-white"
              hidden={false}
              onClick={() => {
                navigate("/digitalsection");
              }}
            >
              {/* <Chart
                                chartType="PieChart"
                                data={data2}
                                options={options}
                                width={"300px"}
                                height={"300px"}
                            /> */}
              {/* <p style={{ 'display': 'flex', 'justifyContent': 'center', 'fontFamily': 'Francois One', 'font-size': '15px' }}>{t('DIRIGENTES DIGITALES')}</p> */}
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default AllDigitalLeaders;
